<template>
  <div>
    <a-spin :spinning="loading">
      <a-descriptions
        :column="1"
        class="iotplt-descriptions iotplt-descriptions-max-6"
      >
        <a-descriptions-item label="数据更新时间">
          {{ data.updated_at }}
        </a-descriptions-item>
      </a-descriptions>

      <a-row>
        <a-col :span="8">
          <a-descriptions
            :column="1"
            class="iotplt-descriptions iotplt-descriptions-max-6"
          >
            <a-descriptions-item label="套餐名称">
              {{ data.product_name }}
            </a-descriptions-item>

            <a-descriptions-item label="运营商种类">
              {{ data.carrier_type }}
            </a-descriptions-item>

            <a-descriptions-item label="用户业务模式">
              {{ data.user_business_type }}
            </a-descriptions-item>

            <a-descriptions-item label="套餐周期">
              {{ data.service_cycle }}
            </a-descriptions-item>

            <a-descriptions-item label="超额资费">
              {{ data.excess_price }}
            </a-descriptions-item>
          </a-descriptions>

          <a-descriptions :column="1">
            <a-descriptions-item label="流量资费">
              <a-descriptions :column="2">
                <a-descriptions-item>采购周期数</a-descriptions-item>
                <a-descriptions-item>价格(元)</a-descriptions-item>
              </a-descriptions>

              <a-descriptions
                :column="2"
                v-for="(favorable_price, index) in data.favorable_price"
                :key="index"
              >
                <a-descriptions-item>{{ favorable_price.cycles }}</a-descriptions-item>
                <a-descriptions-item>{{ favorable_price.distributor_price }}</a-descriptions-item>
              </a-descriptions>
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col :span="8">
          <a-descriptions
            :column="1"
            class="iotplt-descriptions iotplt-descriptions-max-7"
          >
            <a-descriptions-item label="卡号总数">
              {{ data.total_card_count }}
            </a-descriptions-item>

            <a-descriptions-item label="入池卡号总数">
              {{ data.in_pool_card_count }}
            </a-descriptions-item>

            <a-descriptions-item label="沉默期卡数">
              {{ data.silent_period_card_count }}
            </a-descriptions-item>

            <a-descriptions-item label="可用卡数">
              {{ data.available_card_count }}
            </a-descriptions-item>

            <a-descriptions-item label="待续期订购卡数">
              {{ data.to_be_renewed_card_count }}
            </a-descriptions-item>

            <a-descriptions-item label="待销卡数">
              {{ data.to_be_canceled_card_count }}
            </a-descriptions-item>

            <a-descriptions-item label="已销卡数">
              {{ data.canceled_card_count }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col :span="8">
          <a-descriptions
            :column="1"
            class="iotplt-descriptions iotplt-descriptions-max-7"
          >
            <a-descriptions-item label="流量池额定流量">
              {{ data.total_traffic }}
            </a-descriptions-item>

            <a-descriptions-item label="本周期已用流量">
              {{ data.used_traffic }}
            </a-descriptions-item>

            <a-descriptions-item label="本周期剩余流量">
              {{ data.left_traffic }}
            </a-descriptions-item>

            <a-descriptions-item>
              <template slot="label">
                <a-popover label="使用进度">
                  <template slot="content">
                    <p>使用进度 = 本周期已用流量 / 流量池额定流量</p>
                  </template>
                  <a-space>
                    使用进度
                    <a-icon type="question-circle" />
                  </a-space>
                </a-popover>
              </template>
              {{ data.usage_process }}
            </a-descriptions-item>

            <a-descriptions-item label="本周期超额用量">
              {{ data.exceed_traffic }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { findTrafficPool } from '@/api/traffic_pool'
export default {
  name: 'ShowTrafficPool',
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    trafficPoolId() {
      return this.$route.params.id
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findTrafficPool(this.trafficPoolId).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
